<script setup>
import { useModeStore } from '../store/mode.js';
// import { useThemeStore } from '../store/theme.js';
const modeStore = useModeStore();
// const themeStore = useThemeStore();

// eslint-disable-next-line
defineProps({
    image: String,
    name: String,
    role: String,
    id: Number,
})
</script>

<template>
    <router-link :to="`/teachers/${id}`">
        <!-- <div class="avatar flex flex-col gap-2 justify-center content-center" :class="{'text-black': modeStore.selectedMode === 'light', 'text-white': modeStore.selectedMode === 'dark'}">
            <div class="rounded-full h-28 w-28 md:h-32 md:w-32 md:max-w-32 md:max-h-32 object-cover">
                <img :src="image" alt="Avatar"/>
            </div>
            <h2 class="card-title text-center line-clamp-1">{{ name }}</h2>
            <p class="card-description text-center">{{ role }}</p>
        </div> -->
        <div :class="{'text-black': modeStore.selectedMode === 'light', 'text-white': modeStore.selectedMode === 'dark'}" class="min-w-[120px] max-w-[100px] md:min-w-[150px] md:max-w-[150px] flex flex-col">
            <div>
                <img v-if="image" class="rounded-full h-28 w-28 md:h-32 md:w-32 md:max-w-32 md:max-h-32 object-cover" :src="image" alt="Avatar"/>
                <!-- <div :class="{ 'dark-mode-green-avatar-bg': (themeStore.selectedTheme === 'green' && modeStore.selectedMode === 'dark'), 'dark-mode-orange-avatar-bg': (themeStore.selectedTheme === 'orange' && modeStore.selectedMode === 'dark'), 'dark-mode-blue-avatar-bg': (themeStore.selectedTheme === 'blue' && modeStore.selectedMode === 'dark'), 'dark-mode-purple-avatar-bg': (themeStore.selectedTheme === 'purple' && modeStore.selectedMode === 'dark'), 'dark-mode-darkpink-avatar-bg': (themeStore.selectedTheme === 'darkpink' && modeStore.selectedMode === 'dark'), 'light-mode-green-avatar-bg': (themeStore.selectedTheme === 'green' && modeStore.selectedMode === 'light'), 'light-mode-orange-avatar-bg': (themeStore.selectedTheme === 'orange' && modeStore.selectedMode === 'light'), 'light-mode-blue-avatar-bg': (themeStore.selectedTheme === 'blue' && modeStore.selectedMode === 'light'), 'light-mode-purple-avatar-bg': (themeStore.selectedTheme === 'purple' && modeStore.selectedMode === 'light'), 'light-mode-darkpink-avatar-bg': (themeStore.selectedTheme === 'darkpink' && modeStore.selectedMode === 'light')  }" class="rounded-full h-28 w-28 md:h-32 md:w-32 md:max-w-32 md:max-h-32"></div> -->
            </div>
            <div class="line-clamp-1 font-bold">{{ name }}</div>
            <div class="font-ligt">{{ role }}</div>
        </div>
    </router-link>
</template>