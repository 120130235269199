<script setup>
// eslint-disable-next-line
const props = defineProps({
    id: Number,
    image: String,
    topic: String,
    groups: Array,
    subjects: Array,
    postedTime: String,
    tags: Array,
    isAddedToWorkspace: Boolean,
    contentType: Number,
    type: String,
});

import i18next from 'i18next';

import axios from 'axios';
import { computed, ref, defineEmits } from 'vue';
import { useModeStore } from '../store/mode.js';
import { useThemeStore } from '../store/theme.js';
import useIsMobile from '../composables/isMobile.js';
import { getStrokeColor, getModeStrokeColor, getContentTypeLabel } from '../utils/commonFunctions.js';
import { useRouter } from 'vue-router';
import { useAddToPlaylistStore } from '../store/addToPlaylist.js';
import { useDocumentPageFilterStore } from '../store/documentPageFilter.js';
import { useNotificationTopBarStore } from '../store/notificationTopBar.js';

// import AddToPlaylistDropdown from './AddToPlaylistDropdown.vue';

import playIcon from '../assets/icons/play_icon.png';

const addToPlaylistStore = useAddToPlaylistStore();
const documentPageFilterStore = useDocumentPageFilterStore();
const notificationTopBarStore = useNotificationTopBarStore();
const modeStore = useModeStore();
const themeStore = useThemeStore();
const { isMobile } = useIsMobile();
const strokeColor = getStrokeColor();
const modeStrokeColor = getModeStrokeColor();
const isHovered = ref(false);
const emit = defineEmits(['refresh-asset-api']);
const router = useRouter()

const displayTopic = computed(() => {
    return isMobile.value ? `${props.topic.slice(0, 25)}...` : props.topic;
});


const isAddRemoveWorkspaceLoading = ref(false);

const addToWorkspace = async (id) => {
    try {
        isAddRemoveWorkspaceLoading.value = true;
        const response = await axios.post('api/v1/workspaces/asset_broadcasts', {
            asset_broadcast_id: id
        });

        // Check if the response indicates success
        if (response.status === 200 || response.status === 201) { 
            console.log('Added to workspace');

            // Set up notification after successful addition
            notificationTopBarStore.setIdUsedInAction(id);
            notificationTopBarStore.setNotificationMessage(i18next.t('notificationTopBar.addedToWorkspace'));
            notificationTopBarStore.setNotificationType('addAssetBroadcastToWorkspace');
            notificationTopBarStore.setIsShowNotificationTopBar(true);

            // Emit an event to refresh the asset API
            emit('refresh-asset-api');
        } else {
            console.error('Failed to add asset broadcast to workspace:', response);
        }
    } catch (error) {
        console.error('Error adding asset broadcast to workspace:', error);
    } finally {
        isAddRemoveWorkspaceLoading.value = false;
    }
}


const removeFromWorkspace = async (id) => {
    try {
        isAddRemoveWorkspaceLoading.value = true;
        await axios.delete(`api/v1/workspaces/asset_broadcasts/${id}`);
        notificationTopBarStore.setIdUsedInAction(id);
        notificationTopBarStore.setNotificationMessage(i18next.t('notificationTopBar.removedFromWorkspace'));
        notificationTopBarStore.setNotificationType('removeAssetBroadcastFromWorkspace');
        notificationTopBarStore.setIsShowNotificationTopBar(true);
        emit('refresh-asset-api');
    } catch (error) {
        console.error('Error removing playlist from workspace:', error);
    } finally {
        isAddRemoveWorkspaceLoading.value = false;
    }
}

const goToDocument = (id) => {
    if(props.type === 'podcast'){
        router.push({ name: 'Podcast Details', params: { id: id } });
    }
    else{
        router.push({ name: 'DocumentDetailed', params: { id: id } });
    }
}

const showBarAndUpdateSelectedFile = (id) => {
    const checkbox = document.getElementById('checkbox-' + id);
    
    if (checkbox && checkbox.checked) {
        addToPlaylistStore.setShowAddOrCreatePlaylistTopBar(true);
        addToPlaylistStore.setSelectedAssetBroadcastIds([...addToPlaylistStore.selectedAssetBroadcastIds, id]);
    } else { //unchecked
        addToPlaylistStore.setSelectedAssetBroadcastIds(addToPlaylistStore.selectedAssetBroadcastIds.filter(fileId => fileId !== id));
    }

    console.log('selectedAssetBroadcastIds', addToPlaylistStore.selectedAssetBroadcastIds);
}

const goToDocumentPageWithSubjectFilter = (id) => {
    console.log('goToDocumentPageWithSubjectFilter', id); //id here is 202, but in filter it is 133
    documentPageFilterStore.setIsFilterDisplay(true);
    documentPageFilterStore.setFilterBySubject(id);
    router.push('/document');
}

const goToDocumentPageWithCourseLvlFilter = (id) => {
    console.log('goToDocumentPageWithCourseLvlFilter', id); 
    documentPageFilterStore.setIsFilterDisplay(true);
    documentPageFilterStore.setFilterByCourseLevel(id);
    router.push('/document');
}

</script>

<template>
    <div 
        class="card group card-compact rounded-xl p-2 bg-transparent transition-all ease-in-out duration-75"
        :class="{'hover:bg-white hover:bg-opacity-30 text-black': modeStore.selectedMode === 'light', 'hover:bg-white hover:bg-opacity-10 text-white': modeStore.selectedMode === 'dark'}"
    >
        <figure 
            @click.prevent="goToDocument(id)" 
            class="w-full aspect-square relative rounded-xl cursor-pointer overflow-hidden"
        >
            <div v-if="!image" class="w-full h-full"></div>
            <div v-else class="relative w-full h-full flex items-center justify-center">
                <img 
                    :src="image" 
                    alt="Document Image" 
                    class="w-full h-full  object-contain"
                />
                <img 
                    v-if="playIcon"
                    :src="playIcon" 
                    alt="Play Icon"
                    class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-1/5 h-1/5 cursor-pointer"
                />
            </div>
        </figure>
        <div :class="{ 'theme-green': themeStore.selectedTheme === 'green', 'theme-orange': themeStore.selectedTheme === 'orange', 'theme-blue': themeStore.selectedTheme === 'blue', 'theme-purple': themeStore.selectedTheme === 'purple', 'theme-darkpink': themeStore.selectedTheme === 'darkpink'  }" 
            class="absolute right-5 top-5 cursor-pointer md:opacity-0 md:group-hover:opacity-100 transition-opacity duration-200 block md:block">
            <input ref="checkbox" @click="showBarAndUpdateSelectedFile(props.id)" type="checkbox" :id="'checkbox-' + props.id" class="file-checkbox h-5 w-5"/>
            <!-- <AddToPlaylistDropdown :currentFileId="props.id"/> -->
        </div>
        <div class="flex flex-row overflow-hidden">
            <div class="card-body basis-1/5 2xl:basis-4/5 mt-2" style="padding: 0; gap: 0;">
                <a href="#" @click.prevent="goToDocument(id)" class="card-title text-sm md:text-xl cursor-pointer min-h-[30px] md:min-h-[60px] line-clamp-2">{{ displayTopic }}</a>
                <!-- <p class="pb-1 card-description">{{ postedTime }}</p> -->
                <div v-if="(groups && groups.length > 0) || (subjects && subjects.length > 0)" class="md:flex pb-3 overflow-hidden text-sm md:text-base">
                    <div v-if="groups && groups.length > 0">
                        <div  v-for="(group, index) in groups" :key="group.id" class="flex overflow-hidden">
                            <div class="cursor-pointer max-w-[90px] truncate" @click="goToDocumentPageWithCourseLvlFilter(group.id)">{{ group.name }}</div><span v-if="index < groups.length - 1">, </span>
                        </div>
                    </div>
                    <div v-if="subjects && subjects.length > 0">
                        <span class="px-1 hidden md:block"> | </span>
                    </div>
                    <div v-if="subjects && subjects.length > 0">
                        <div v-for="(subject, index) in subjects" :key="subject.id" class="flex overflow-hidden w-full">
                            <div class="cursor-pointer line-clamp-1" @click="goToDocumentPageWithSubjectFilter(subject.id)">{{ subject.name }}</div><span v-if="index < subjects.length - 1">, </span>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="props.isAddedToWorkspace != null" id="step-7" class="flex flex-row justify-end items-start mt-[0.3rem]">
                <div v-if="isAddedToWorkspace" @click="removeFromWorkspace(id)"  class="cursor-pointer">
                    <div v-show="isAddRemoveWorkspaceLoading" class="spinner" :style="{borderLeftColor: strokeColor}"></div>
                    <svg v-show="!isAddRemoveWorkspaceLoading" width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M18.75 5.46875H6.25C5.82292 5.46875 5.46875 5.11458 5.46875 4.6875C5.46875 4.26042 5.82292 3.90625 6.25 3.90625H18.75C19.1771 3.90625 19.5312 4.26042 19.5312 4.6875C19.5312 5.11458 19.1771 5.46875 18.75 5.46875Z" :fill="strokeColor"/>
                        <path d="M15.625 2.86523H9.375C8.94792 2.86523 8.59375 2.51107 8.59375 2.08398C8.59375 1.6569 8.94792 1.30273 9.375 1.30273H15.625C16.0521 1.30273 16.4062 1.6569 16.4062 2.08398C16.4062 2.51107 16.0521 2.86523 15.625 2.86523Z" :fill="strokeColor"/>
                        <path d="M14.4896 16.8848C14.1354 16.8848 13.8438 17.1764 13.8438 17.5306C13.8438 17.8848 14.1354 18.1764 14.4896 18.1764C14.8437 18.1764 15.1354 17.8848 15.1354 17.5306C15.1354 17.1764 14.8437 16.8848 14.4896 16.8848Z" :fill="strokeColor"/>
                        <path d="M9.9069 18.4792C9.9069 18.125 9.61523 17.8438 9.26107 17.8438C8.9069 17.8438 8.61523 18.1354 8.61523 18.4896C8.61523 18.8438 8.9069 19.1354 9.26107 19.1354C9.61523 19.125 9.9069 18.8333 9.9069 18.4792Z" :fill="strokeColor"/>
                        <path d="M18.7507 7.29102H6.25065C3.95898 7.29102 2.08398 9.16602 2.08398 11.4577V18.7493C2.08398 21.041 3.95898 22.916 6.25065 22.916H18.7507C21.0423 22.916 22.9173 21.041 22.9173 18.7493V11.4577C22.9173 9.16602 21.0423 7.29102 18.7507 7.29102Z" :fill="strokeColor"/>
                    </svg>
                </div>
                <div v-else @click="addToWorkspace(id)" class="cursor-pointer text-black" @mouseover="isHovered = true" @mouseout="isHovered = false">
                    <div v-show="isAddRemoveWorkspaceLoading" class="spinner" :style="{borderLeftColor: strokeColor}"></div>
                    <svg v-show="!isAddRemoveWorkspaceLoading" width="40" height="40" viewBox="0 5 30 45"  fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M30.4167 21.0423V25.209C30.4167 28.8548 28.3334 30.4173 25.2084 30.4173H14.7917C11.6667 30.4173 9.58337 28.8548 9.58337 25.209V21.0423C9.58337 18.2819 10.7813 16.7194 12.7084 16.1257C13.3334 15.9278 14.0313 15.834 14.7917 15.834H25.2084C25.9688 15.834 26.6667 15.9278 27.2917 16.1257C29.2188 16.7194 30.4167 18.2819 30.4167 21.0423Z" :stroke="isHovered ? strokeColor : modeStrokeColor" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M27.2917 14.7943V16.1276C26.6667 15.9297 25.9688 15.8359 25.2084 15.8359H14.7917C14.0313 15.8359 13.3334 15.9297 12.7084 16.1276V14.7943C12.7084 13.6484 13.6459 12.7109 14.7917 12.7109H25.2084C26.3542 12.7109 27.2917 13.6484 27.2917 14.7943Z" :stroke="isHovered ? strokeColor : modeStrokeColor" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M24.1666 11.1569V12.709H15.8333V11.1569C15.8333 10.2923 16.5416 9.58398 17.4062 9.58398H22.5937C23.4582 9.58398 24.1666 10.2923 24.1666 11.1569Z" :stroke="isHovered ? strokeColor : modeStrokeColor" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>
            </div>
        </div>
        <div class="flex flex-wrap gap-2">
            <!-- <div v-for="tag in tags" :key="tag" class="badge badge-lg border-0 rounded-xl bg-[#FEF3C7] text-[#92400E] h-auto">{{ tag.name }}</div> -->
            <div v-if="contentType" class="badge badge-lg border-0 rounded-xl h-auto"
            :class="{
            'bg-[#FEF3C7] text-[#92400E]': contentType === 1,
            'bg-green-100 text-green-800': contentType === 2,
            'bg-purple-100 text-purple-800': contentType === 3
        }"
            >
            {{ getContentTypeLabel(contentType) }}
            </div>
        </div>
    </div>
</template>