<script setup>
// eslint-disable-next-line
const props = defineProps({
    playlistId: Number,
    image: String,
    subject: String,
    teacher: String,
    grade: Array,
    documentCount: Number,
    interactionCount: Number,
    videoCount: Number,
    isAddedToWorkspace: Boolean,
    isTeacherPlaylist: Boolean,
});

import i18next from 'i18next';

import axios from 'axios';
import { ref, defineEmits } from 'vue';
import { useModeStore } from '../store/mode.js';
import { useWorkspaceStore } from '../store/workspace.js';
import { getStrokeColor, getModeStrokeColor } from '../utils/commonFunctions.js';
import { useRouter } from 'vue-router';
import { useNotificationTopBarStore } from '../store/notificationTopBar.js';

const modeStore = useModeStore();
const strokeColor = getStrokeColor();
const modeStrokeColor = getModeStrokeColor();
const workspaceStore = useWorkspaceStore();
const isHovered = ref(false);
const emit = defineEmits(['refresh-playlist-api']);
const router = useRouter();
const isAddRemoveWorkspaceLoading = ref(false);
const notificationTopBarStore = useNotificationTopBarStore();

const addToWorkspace = async (playlistId) => {
    try {
        isAddRemoveWorkspaceLoading.value = true;
        await axios.post('api/v1/workspaces/playlists', {
            playlist_id: playlistId
        });
        workspaceStore.setAction('add');
        workspaceStore.setPlaylistId(playlistId);

        notificationTopBarStore.setIdUsedInAction(playlistId);
        notificationTopBarStore.setNotificationMessage(i18next.t('notificationTopBar.addedToWorkspace'));
        notificationTopBarStore.setNotificationType('addPlaylistToWorkspace');
        notificationTopBarStore.setIsShowNotificationTopBar(true);
        emit('refresh-playlist-api');
    } catch (error) {
        console.error('Error adding playlist to workspace:', error);
    } finally {
        isAddRemoveWorkspaceLoading.value = false;
    }
}


const removeFromWorkspace = async (playlistId) => {
    try {
        isAddRemoveWorkspaceLoading.value = true;
        await axios.delete(`api/v1/workspaces/playlists/${playlistId}`);
        workspaceStore.setAction('remove');
        workspaceStore.setPlaylistId(playlistId);

        notificationTopBarStore.setIdUsedInAction(playlistId);
        notificationTopBarStore.setNotificationMessage(i18next.t('notificationTopBar.removedFromWorkspace'));
        notificationTopBarStore.setNotificationType('removePlaylistFromWorkspace');
        notificationTopBarStore.setIsShowNotificationTopBar(true);
        emit('refresh-playlist-api');
    } catch (error) {
        console.error('Error removing playlist from workspace:', error);
    } finally {
        isAddRemoveWorkspaceLoading.value = false;
    }
}

// const gradeString = computed(() => props.grade.join(' | '));

const goToPlaylistPlay = () => {
    router.push({
        name: 'Play Playlist',
        params: { id: props.playlistId },
        query: { isTeacherPlaylist: props.isTeacherPlaylist }
    });
}
</script>

<template>
    <div 
    class="card card-compact rounded-[30px] p-[10px] group transition-all ease-in-out duration-75"
    :class="{'hover:bg-white hover:bg-opacity-30 text-black': modeStore.selectedMode === 'light', 'hover:bg-white hover:bg-opacity-10 text-white': modeStore.selectedMode === 'dark'}"
    >
        <figure class="w-full rounded-[30px] h-36 xl:h-48 2xl:h-72 cursor-pointer" @click="goToPlaylistPlay">
            <div v-if="!image" class="bg-white bg-opacity-20 min-w-full min-h-full"></div>
            <div v-else>
                <img class="w-full h-full object-cover bg-gray-400" 
                :src="image" alt="Playlist Card Image" />
            </div>
        </figure>
        <div class="card-body mt-2" style="padding: 0; gap: 0;">
            <div class="flex justify-between items-start">
                <div class="">
                    <div class="flex flex-row items-start justify-start w-full">
                        <h2 class="text-lg leading-7 font-semibold cursor-pointer line-clamp-2 max-w-2/3 min-h-[60px]" @click="goToPlaylistPlay">{{ subject }}</h2>
                    </div>
                    <!-- <div class="pb-1 card-description">{{ gradeString }}</div> -->
                </div>
                <div class="flex items-start justify-end">
                    <div v-if="isAddedToWorkspace" @click="removeFromWorkspace(playlistId)"  class="my-auto cursor-pointer">
                        <div v-show="isAddRemoveWorkspaceLoading" class="spinner" :style="{borderLeftColor: strokeColor}"></div>
                        <svg v-show="!isAddRemoveWorkspaceLoading" width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M18.75 5.46875H6.25C5.82292 5.46875 5.46875 5.11458 5.46875 4.6875C5.46875 4.26042 5.82292 3.90625 6.25 3.90625H18.75C19.1771 3.90625 19.5312 4.26042 19.5312 4.6875C19.5312 5.11458 19.1771 5.46875 18.75 5.46875Z" :fill="strokeColor"/>
                            <path d="M15.625 2.86523H9.375C8.94792 2.86523 8.59375 2.51107 8.59375 2.08398C8.59375 1.6569 8.94792 1.30273 9.375 1.30273H15.625C16.0521 1.30273 16.4062 1.6569 16.4062 2.08398C16.4062 2.51107 16.0521 2.86523 15.625 2.86523Z" :fill="strokeColor"/>
                            <path d="M14.4896 16.8848C14.1354 16.8848 13.8438 17.1764 13.8438 17.5306C13.8438 17.8848 14.1354 18.1764 14.4896 18.1764C14.8437 18.1764 15.1354 17.8848 15.1354 17.5306C15.1354 17.1764 14.8437 16.8848 14.4896 16.8848Z" :fill="strokeColor"/>
                            <path d="M9.9069 18.4792C9.9069 18.125 9.61523 17.8438 9.26107 17.8438C8.9069 17.8438 8.61523 18.1354 8.61523 18.4896C8.61523 18.8438 8.9069 19.1354 9.26107 19.1354C9.61523 19.125 9.9069 18.8333 9.9069 18.4792Z" :fill="strokeColor"/>
                            <path d="M18.7507 7.29102H6.25065C3.95898 7.29102 2.08398 9.16602 2.08398 11.4577V18.7493C2.08398 21.041 3.95898 22.916 6.25065 22.916H18.7507C21.0423 22.916 22.9173 21.041 22.9173 18.7493V11.4577C22.9173 9.16602 21.0423 7.29102 18.7507 7.29102Z" :fill="strokeColor"/>
                        </svg>
                    </div>
                    <div v-else @click="addToWorkspace(playlistId)" class="my-auto cursor-pointer text-black" @mouseover="isHovered = true" @mouseout="isHovered = false">
                        <div v-show="isAddRemoveWorkspaceLoading" class="spinner" :style="{borderLeftColor: strokeColor}"></div>
                        <svg v-show="!isAddRemoveWorkspaceLoading" width="40" height="40" viewBox="0 5 30 45"  fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M30.4167 21.0423V25.209C30.4167 28.8548 28.3334 30.4173 25.2084 30.4173H14.7917C11.6667 30.4173 9.58337 28.8548 9.58337 25.209V21.0423C9.58337 18.2819 10.7813 16.7194 12.7084 16.1257C13.3334 15.9278 14.0313 15.834 14.7917 15.834H25.2084C25.9688 15.834 26.6667 15.9278 27.2917 16.1257C29.2188 16.7194 30.4167 18.2819 30.4167 21.0423Z" :stroke="isHovered ? strokeColor : modeStrokeColor" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M27.2917 14.7943V16.1276C26.6667 15.9297 25.9688 15.8359 25.2084 15.8359H14.7917C14.0313 15.8359 13.3334 15.9297 12.7084 16.1276V14.7943C12.7084 13.6484 13.6459 12.7109 14.7917 12.7109H25.2084C26.3542 12.7109 27.2917 13.6484 27.2917 14.7943Z" :stroke="isHovered ? strokeColor : modeStrokeColor" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M24.1666 11.1569V12.709H15.8333V11.1569C15.8333 10.2923 16.5416 9.58398 17.4062 9.58398H22.5937C23.4582 9.58398 24.1666 10.2923 24.1666 11.1569Z" :stroke="isHovered ? strokeColor : modeStrokeColor" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                </div>
            </div>

            <div 
                class="rounded-[30px] grid grid-cols-3 gap-2 py-2 px-2"
                :class="{'group-hover:bg-custom-light-gray': modeStore.selectedMode === 'light', 'group-hover:bg-custom-blue-gray': modeStore.selectedMode === 'dark'}"
            >
                <div class="playlist-card-description flex items-center justify-center">{{ documentCount }} {{ $t('general.document') }}</div>
                <div class="playlist-card-description flex items-center justify-center border-x-2">{{ interactionCount }} {{ $t('general.interactive') }}</div>
                <div class="playlist-card-description flex items-center justify-center">{{ videoCount }} {{ $t('general.video') }}</div>
            </div>
        </div>
    </div>
</template>